<template>
  <div class="content">
    <div class="orderCensus">
      <div class="NewToday">
        <p class="number green">{{DataNum1.today_workorder_count||0}}</p>
        <el-tooltip class="item" effect="dark" :content="$t('今日新增工单')" placement="bottom">
          <p class="title">{{$t('今日新增工单')}}</p>
        </el-tooltip>
      </div>
      <div class="incomplete">
        <p class="number red">{{DataNum1.not_complete_workorder_count||0}}</p>
        <el-tooltip class="item" effect="dark" :content="$t('未完成工单')" placement="bottom">
          <p class="title">{{$t('未完成工单')}}</p>
        </el-tooltip>
      </div>
      <div class="abnormal">
        <p class="number">{{DataNum1.abnormal_workorder_count||0}}</p>
        <el-tooltip class="item" effect="dark" :content="$t('异常工单')" placement="bottom">
          <p class="title">{{$t('异常工单')}}</p>
        </el-tooltip>
      </div>
      <div class="warning">
        <p class="number yellow">{{DataNum1.early_warning_information||0}}</p>
        <el-tooltip class="item" effect="dark" :content="$t('预警信息')" placement="bottom">
          <p class="title">{{$t('预警信息')}}</p>
        </el-tooltip>
      </div>
      <div class="remind">
        <p class="number">{{DataNum1.reminder_message||0}}</p>
        <el-tooltip class="item" effect="dark" :content="$t('提醒消息')" placement="bottom">
          <p class="title">{{$t('提醒消息')}}</p>
        </el-tooltip>
      </div>
      <div class="NewMonth">
        <p class="number green">{{DataNum1.month_workorder_count||0}}</p>
        <el-tooltip class="item" effect="dark" :content="$t('本月新增工单')" placement="bottom">
          <p class="title">{{$t('本月新增工单')}}</p>
        </el-tooltip>
      </div>
      <div class="AllOrder">
        <p class="number orange">{{DataNum1.all_workorder_count||0}}</p>
        <el-tooltip class="item" effect="dark" :content="$t('全部工单')" placement="bottom">
          <p class="title">{{$t('全部工单')}}</p>
        </el-tooltip>
      </div>
    </div>
    <!-- 流程进度条 -->
    <div class="steps">
      <!--  -->
      <el-badge :value="DataNum2.yy_subscribe_count||0" class="item">
        <div class="Report" @click="toyy">
          <i class="bi bi-pencil-square"></i>
          <el-tooltip class="item" effect="dark" :content="$t('预约工单')" placement="bottom">
            <span class="steps_name">{{$t('预约工单')}}</span>
          </el-tooltip>
        </div>
      </el-badge>
      <!--  -->
      <i class="bi bi-chevron-right"></i>
      <!--  -->
      <el-badge :value="DataNum2.dispatch_count||0" class="item">
        <div class="Report" @click="toUpOrder">
          <i class="bi bi-pencil-square"></i>
          <el-tooltip class="item" effect="dark" :content="$t('上报工单')" placement="bottom">
            <span class="steps_name">{{$t('上报工单')}}</span>
          </el-tooltip>
        </div>
      </el-badge>
      <!--  -->
      <i class="bi bi-chevron-right"></i>
      <!--  -->
      <el-badge :value="DataNum2.order_taking_count||0" class="item">
        <div class="receive" @click="toUpOrder(1)">
          <i class="bi bi-hourglass-split"></i>
          <el-tooltip class="item" effect="dark" :content="$t('待接单')" placement="bottom">
            <span class="steps_name">{{$t('待接单')}}</span>
          </el-tooltip>
        </div>
      </el-badge>
      <!--  -->
      <i class="bi bi-chevron-right"></i>
      <!--  -->
      <el-badge :value="DataNum2.sign_count||0" class="item">
        <div class="Sign" @click="toUpOrder(2)">
          <i class="bi bi-geo-alt"></i>
          <el-tooltip class="item" effect="dark" :content="$t('待签到')" placement="bottom">
            <span class="steps_name">{{$t('待签到')}}</span>
          </el-tooltip>
        </div>
      </el-badge>
      <!--  -->
      <i class="bi bi-chevron-right"></i>
      <!--  -->
      <el-badge :value="DataNum2.complete_count||0" class="item">
        <div class="finish" @click="toUpOrder(3)">
          <i class="bi bi-clock-history"></i>
          <el-tooltip class="item" effect="dark" :content="$t('待完工')" placement="bottom">
            <span class="steps_name">{{$t('待完工')}}</span>
          </el-tooltip>
        </div>
      </el-badge>
      <!--  -->
      <i class="bi bi-chevron-right"></i>
      <!--  -->
      <el-badge :value="DataNum2.toexamine_count||0" class="item">
        <div class="examine" @click="toUpOrder(4)">
          <i class="bi bi-search"></i>
          <el-tooltip class="item" effect="dark" :content="$t('待审核')" placement="bottom">
            <span class="steps_name">{{$t('待审核')}}</span>
          </el-tooltip>
        </div>
      </el-badge>
      <!--  -->
      <i class="bi bi-chevron-right"></i>
      <!--  -->
      <el-badge :value="DataNum2.finish_count||0" class="item">
        <div class="Completed" @click="toUpOrder(5)">
          <i class="bi bi-clipboard2-check"></i>
          <el-tooltip class="item" effect="dark" :content="$t('已完成')" placement="bottom">
            <span class="steps_name">{{$t('已完成')}}</span>
          </el-tooltip>
        </div>
      </el-badge>
      <!--  -->
      <i class="bi bi-chevron-right"></i>
      <!--  -->
      <el-badge :value="DataNum2.tobe_evaluated_count||0" class="item">
        <div class="evaluate" @click="toUpOrder('','0')">
          <i class="bi bi-chat-heart"></i>
          <el-tooltip class="item" effect="dark" :content="$t('待评价')" placement="bottom">
            <span class="steps_name">{{$t('待评价')}}</span>
          </el-tooltip>
        </div>
      </el-badge>
      <!--  -->
    </div>
    <div class="Echarts">
      <el-tabs v-model="activeName" @tab-click="getIndex">
        <el-tab-pane :label="$t('故障率统计')" name="faultsNum">
          <div class="datePicker">
            <el-date-picker v-model="TimeValue" type="datetimerange" @change="getReport(TimeValue)" align="right"
              unlink-panels size='small' range-separator="-" :picker-options="pickerOptions" :start-placeholder="$t('开始日期')" value-format="yyyy-MM-dd HH:mm:ss"
              :end-placeholder="$t('结束日期')">
            </el-date-picker>
          </div>
          <div class="failureRate"></div>
        </el-tab-pane>
        <el-tab-pane :label="$t('工单故障类型统计')" name="faultType">
          <el-tabs tab-position="left" v-model="activeFType" style="width: 100%;">
            <div class="search">
              <el-select v-model="Operator" :placeholder="$t('可筛选') " clearable @change="dgFault(Operator)">
                <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="FTall"></div>
          </el-tabs>
        </el-tab-pane>
        <el-tab-pane :label="$t('平台故障类型统计')" name="platformFaultType">
          <div class="platformFaultType"></div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {
      ROOT_PATH: 'https://cdn.jsdelivr.net/gh/apache/echarts-website@asf-site/examples',
      failureRate: '',
      activeName: 'faultsNum',
      activeFType: 'FTALL',
      pickerOptions: {
        shortcuts: [{
          text: this.$t('最近一周'),
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: this.$t('最近一个月'),
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: this.$t('最近三个月'),
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      // 时间的value
      TimeValue: '',
      DataNum1: {},
      DataNum2: {},
      options: [],
      Operator: ''
    }
  },
  mounted () {
    // this.getFaultsNum()
    this.getsummary()
    this.getReport()
  },
  methods: {
    // 获取故障率统计
    getFaultsNum (data) {
      var failureRate = this.$echarts.init(document.querySelector('.failureRate'), null, {
        height: 400
      })
      // console.log(_rawData)
      var option = {
        dataset: [{
          id: 'dataset_raw'
        },
        {
          id: 'dataset_since_1950_of_germany',
          fromDatasetId: 'dataset_raw',
          transform: {
            type: 'filter',
            config: {
              and: [{
                dimension: 'Year',
                gte: 1950
              },
              {
                dimension: 'Country',
                '=': 'Germany'
              }
              ]
            }
          }
        }
        ],
        title: {
          text: this.$t('故障数量'),
          left: 'center'
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          nameLocation: 'middle'
        },
        yAxis: {},
        series: [{
          type: 'line',
          datasetId: 'dataset_since_1950_of_germany',
          showSymbol: false,
          encode: {
            x: 'Year',
            y: 'Income',
            itemName: 'Year',
            tooltip: ['Income']
          },
          data: data || []
        }]
      }
      failureRate.setOption(option)
    },
    // 获取总故障类型统计
    getFTall (data) {
      var FTall = this.$echarts.init(document.querySelector('.FTall'), null, {
        width: 1500,
        height: 400
      })
      var option = {
        title: {
          text: this.$t('故障类型'),
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [{
          // name: 'Access From',
          type: 'pie',
          radius: '50%',
          data: data || [],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }]
      }
      FTall.setOption(option)
    },
    // 获取后台故障类型统计
    getBgFaultType (data) {
      var platformFaultType = this.$echarts.init(document.querySelector('.platformFaultType'), null, {
        width: 1000,
        height: 400
      })
      var option = {
        title: {
          text: this.$t('后台故障类型'),
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [{
          type: 'pie',
          radius: '50%',
          data: data || [],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }]
      }
      platformFaultType.setOption(option)
    },
    // 获取当前标签页
    getIndex () {
      // console.log(this.activeName)
      if (this.activeName === 'faultsNum') {
        this.getReport()
      } else if (this.activeName === 'faultType') {
        this.getID()
        this.dgFault()
      } else if (this.activeName === 'platformFaultType') {
        this.warningInfoFault()
      }
    },
    // 获取统计数据
    getsummary () {
      this.$axios.post('index/summary').then((res) => {
        // console.log(res)
        var data = res.data
        if (data.code === 0) {
          this.DataNum1 = data.data.summaty1
          this.DataNum2 = data.data.summaty2
        }
      }).catch((err) => this.$message.error(err))
    },
    // 获取故障数量
    getReport (time) {
      // console.log(time)
      var start, end
      if (time) {
        start = time[0]
        end = time[1]
      } else {
        var time1 = new Date()
        time1.setTime(time1.getTime() - 3600 * 1000 * 24 * 7)
        start = this.$common.formatTime(time1)
        end = this.$common.formatTime(new Date())
        this.TimeValue = [start, end]
      }
      this.$axios.post('index/gdReport', {
        start_time: start,
        end_time: end
      }).then((res) => {
        // console.log(res)
        var data = res.data
        if (data.code === 0) {
          this.getFaultsNum(data.data)
        } else {
          this.$message.warning(data.message)
        }
      }).catch((err) => this.$message.error(err))
    },
    // 获取故障类型
    dgFault (oid) {
      this.$axios.post('index/dgFault', {
        oc_id: oid
      }).then((res) => {
        // console.log(res)
        var data = res.data
        if (data.code === 0) {
          this.getFTall(data.data)
        } else this.$message.warning(data.message)
      }).catch((err) => { this.$message.error(err) })
    },
    // 获取所有客户ID
    getID () {
      this.$axios.post('customer/getAll').then((res) => {
        var data = res.data
        if (data.code === 0) {
          this.options = data.data
          // console.log(this.options)
        } else {
          this.$message.warning(data.message)
        }
      }).catch((err) => this.$message.error(err))
    },
    // 第三方故障类型统计
    warningInfoFault () {
      this.$axios.post('index/warningInfoFault').then((res) => {
        // console.log(res)
        var data = res.data
        if (data.code === 0) {
          this.getBgFaultType(data.data)
        } else this.$message.warning(data.message)
      }).catch((err) => { this.$message.error(err) })
    },
    // 跳转到预约
    toyy () {
      this.$router.push({ name: 'Subscribe' })
    },
    // 跳转到工单列表
    toUpOrder (status, evaluate) {
      this.$router.push({ name: 'QueryOrder', params: { status, evaluate } })
    }
  }
}
</script>

<style scoped>
  ::v-deep .bi {
    /* 23px */
    font-size: 1.4375rem !important;
  }

  .steps {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .steps .steps_name{
    width: 90px;
    overflow: hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
    text-align: center;
  }
  .Report,
  .receive,
  .Sign,
  .finish,
  .examine,
  .Completed,
  .evaluate {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #778f8c;
  }

  ::v-deep .bi-chevron-right {
    color: #ff8d3c;
  }

  /*  */
  .orderCensus {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* 20px */
    padding: 20px 0 30px;
    border-bottom: 1px dashed #b9b9b9;
    margin-bottom: 40px;
  }

  .orderCensus>div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid #b9b9b9;
    color: #778f8c;
    /* 16px */
    font-size: 1rem;
    padding: 0 10px;
  }

  .orderCensus>div:last-child {
    border: none;
  }
  .orderCensus p{
    width: 160px;
    text-align: center;
    overflow: hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
  }
  .orderCensus .number {
    /* 25px */
    font-size: 1.5625rem;
    font-weight: 700;
    /* 12px */
    margin-bottom: 0.75rem;
  }

  .green {
    color: #14ce00;
  }

  .red {
    color: #ef0f0f;
  }

  .yellow {
    color: #ebb904;
  }

  .orange {
    color: #ff8000;
  }

  .Echarts {
    width: 100%;
    margin-top: 80px;
  }

  .failureRate,
  .FTall {
    width: 100%;
    height: 400px;
  }

  .datePicker {
    text-align: right;
  }

  ::v-deep .el-select,
  ::v-deep .el-date-editor.el-input {
    width: 100% !important;
  }

  .avatar-uploader::v-deep .el-upload {
    border: 1px dashed #7a7a7a;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader::v-deep .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
  }

  .avatar {
    width: 120px;
    height: 120px;
    display: block;
  }

  .search{
   width: 300px;
   margin-left: 900px;
  }
  ::v-deep .el-input__inner{
    border: 1px solid #a09bd3;
  }
  .item{
    cursor: pointer;
  }
  .item:hover span{
    color: #ff263b;
  }
</style>
